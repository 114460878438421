<template>
  <div class="menu_main">
    <div class="menu-title flex">
      <span :class="{ checked: titleIndex == 1 }" @click="handleChangeTitle(1)"
        >图书分类</span
      >
      <span :class="{ checked: titleIndex == 2 }" @click="handleChangeTitle(2)"
        >中图法分类</span
      >
    </div>
    <div class="ztree">
      <div class="menu-item">
        <ul>
          <li class="menu-one" v-for="(item, index) in classifyList" :key="index">
            <div class="menu-one-page flex">
              <img
                v-if="item.children != null && item.children.length > 0"
                :src="item.flag == undefined || item.flag ? firstJianImg : firstAddImg"
                @click="handleOneChild(index, item.id)"
              />
              <div style="width: 15px; height: 15px; margin-right: 10px" v-else></div>
              <span
                :title="item.name + '（' + item.count + '）'"
                class="line1"
                :class="{ onechecked: item.onechecked }"
                @click="handleOneChecked(index, item.id)"
                >{{ item.name }}（{{ item.count }}）</span
              >
            </div>

            <ul
              v-show="
                (item.flag == undefined || item.flag) &&
                item.children != null &&
                item.children.length > 0
              "
            >
              <li class="menu-two" v-for="(element, idx) in item.children" :key="idx">
                <div class="menu-two-page flex">
                  <img
                    v-if="element.children != null && element.children.length > 0"
                    :src="element.flag ? jianImg : addImg"
                    @click="handleTwoChild(index, idx, element.id)"
                  />
                  <img v-else-if="idx == 0" :src="nochildImg" />
                  <img
                    v-else-if="idx == item.children.length - 1"
                    :src="lastnochildImg"
                  />
                  <img v-else :src="nochildImg" />
                  <!-- <div
                    style="width: 15px; height: 15px; margin-right: 10px"
                    v-else
                  ></div> -->
                  <div
                    :title="element.name + '（' + element.count + '）'"
                    :class="{ twochecked: element.twochecked }"
                    @click="handleTwoChecked(index, idx, element.id)"
                  >
                    <span class="line1">{{ element.name }}</span
                    >（{{ element.count }}）
                  </div>
                </div>
                <ul v-show="element.flag" class="verticalline">
                  <li
                    class="menu-three"
                    v-for="(list, listidx) in element.children"
                    :key="listidx"
                  >
                    <div class="menu-three-page flex">
                      <img
                        v-if="list.children != null && list.children.length > 0"
                        :src="list.flag ? jianImg : addImg"
                        @click="handleThreeChild(index, idx, listidx, list.id)"
                      />
                      <img v-else-if="listidx == 0" :src="nochildImg" />
                      <img
                        v-else-if="listidx === element.children.length - 1"
                        :src="lastnochildImg"
                      />

                      <img v-else :src="nochildImg" />
                      <!-- <div
                        style="width: 15px; height: 15px; margin-right: 10px"
                        v-else
                      ></div> -->
                      <div
                        :title="list.name + '（' + list.count + '）'"
                        :class="{ threechecked: list.threechecked }"
                        @click="handleThreeChecked(index, idx, listidx, list.id)"
                      >
                        <span class="line1">{{ list.name }}</span
                        >（{{ list.count }}）
                      </div>
                    </div>
                    <ul v-show="list.flag" class="verticalline">
                      <li
                        class="menu-four"
                        v-for="(listfour, listidxfour) in list.children"
                        :key="listidxfour"
                      >
                        <div
                          class="menu-four-page flex"
                          :class="{ fourchecked: listfour.fourchecked }"
                          @click="handleFourChecked(index, idx, listidx, listidxfour)"
                        >
                          <img
                            v-if="listidxfour === list.children.length - 1"
                            :src="lastnochildImg"
                          />

                          <img v-else :src="nochildImg" />
                          <div
                            :title="listfour.name + '（' + listfour.count + '）'"
                            class="tree3"
                          >
                            <span class="line1">{{ listfour.name }}</span
                            >（{{ listfour.count }}）
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <!-- <template v-for="(item1, index1) in commonData">
      <div class="menu-title title-year">
        {{ item1.name }}
      </div>
      <div class="year-item">
        <ul>
          <li
            @click="handleYear(index, item, item1)"
            :class="{ checked: yearIndex == index }"
            v-for="(item, index) in computedItems(item1)"
            :key="index"
          >
            {{ item.name }}（{{ item.count }}）
          </li>
        </ul>
        <p
          style="display: inline-block"
          v-if="
            item1.aggItems.length > 5 &&
            item1.addIndex * 5 < item1.aggItems.length
          "
          @click="handleChangeFlag(item1, false)"
        >
          更多<img :src="xiala" />
        </p>
        <p
          style="display: inline-block"
          v-if="
            item1.aggItems.length > 5 && item1.addIndex && item1.addIndex > 1
          "
          @click="handleChangeFlag(item1, true)"
        >
          收起<img :src="shouqi" />
        </p>
      </div>
    </template> -->
  </div>
</template>

<script>
import { handleTree, getPathByKey, treeFind } from "@/utils/cxstar";
import { json } from "body-parser";
export default {
  name: "leftMenu",
  props: {
    leftdata: {
      type: Array,
      default() {
        return [];
      },
    },
    subjectId: [],
  },
  computed: {
    // sqFlag() {
    //   if (this.commonData.length > 0) {
    //     if (this.commonData[0].aggItems.length > 5) {
    //       if (this.commonData[0].addIndex && this.commonData[0].addIndex > 1) {
    //         if (
    //           this.commonData[0].aggItems.length <=
    //           this.commonData[0].addIndex * 5
    //         ) {
    //         }
    //       }
    //     }
    //   }
    //   return false;
    // },
    // gdFlag() {
    //   if (this.commonData.length > 0) {
    //     if (this.commonData[0].aggItems.length > 5) {
    //       if (this.commonData[0].addIndex && this.commonData[0].addIndex > 1) {
    //         return true;
    //       }
    //     }
    //   }
    //   return false;
    // },
  },
  watch: {
    leftdata: {
      handler(newValue, oldValue) {
        // let n = JSON.parse(JSON.stringify(newValue));
        let n = newValue;
        if (n.length > 0) {
          // obj 将左侧聚合项数据由数组转换为对象，如{学科分类：xxx,中图法分类：xxx,出版年：xxx}
          let obj = {};
          // arr c缓存出版年的数据
          let arr = [];
          // yearIndex  选中的出版年的下标index
          this.yearIndex = this.$store.state.searchParam.yearIndex;
          // yearMore 是否展开更多
          this.yearMore = this.$store.state.searchParam.yearMore;
          // titleIndex vuex中中图法分类的字段如果有值，切换到中图法分类
          if (this.$store.state.searchParam.clcIds.length > 0) {
            this.titleIndex = 2;
          }
          // 遍历左侧聚合项新数据
          n.forEach(element => {
            // 将接口返回的左侧数组数据分组转换为对象，方便按区域展示
            obj[element.name] = element.aggItems;
            /**
             * classifyList 分类树状结构，判断titleIndex当前是图书分类还是中图法分类
             * 二者共用classifyList数据源
             * **/
            if (element.name == "图书分类" && this.titleIndex == 1) {
              this.classifyList = element.aggItems;
            } else if (element.name == "中图法分类" && this.titleIndex == 2) {
              this.classifyList = element.aggItems;
            }
            // 名称不是中图法和学科，那就是出版年，赋值给arr
            if (element.name != "图书分类" && element.name != "中图法分类") {
              arr.push(element);
            }
          });

          // 将处理好的数据对象obj赋值给leftObj
          this.leftObj = obj;
          // 出版年数据（换言之就是普通列表数据）
          this.commonData = arr;
          /**
           *  this.commonData[0]当前是第一个普通列表数据也就是出版年
           *  addIndex  记录下拉了第几次，每一次点击更多展示五条，比如展示15条就是3次
           *  addIndex=3
           * **/
          // this.commonData[0].addIndex = this.$store.state.searchParam.addIndex;
          // 保存记录列表页上方搜索条件和左侧筛选条件的展示回显
          let topArray = this.$store.state.searchParam.topArray;
          // 判断topArray有值，代表是从首页、高级检索通过条件进入该页面，需要回显这些页面传过来的参数
          if (topArray && topArray.length > 0) {
            if (
              topArray[0] != undefined &&
              topArray[1] == undefined &&
              topArray[2] == undefined &&
              topArray[3] == undefined
            ) {
              this.$set(this.classifyList[topArray[0]], "onechecked", true);
            }
            if (
              topArray[0] != undefined &&
              topArray[1] != undefined &&
              topArray[2] == undefined &&
              topArray[3] == undefined
            ) {
              this.$set(this.classifyList[topArray[0]], "flag", true);
              this.$set(
                this.classifyList[topArray[0]].children[topArray[1]],
                "twochecked",
                true
              );
            }
            if (
              topArray[0] != undefined &&
              topArray[1] != undefined &&
              topArray[2] != undefined &&
              topArray[3] == undefined
            ) {
              this.$set(
                this.classifyList[topArray[0]].children[topArray[1]],
                "flag",
                true
              );
              this.$set(
                this.classifyList[topArray[0]].children[topArray[1]].children[
                  topArray[2]
                ],
                "flag",
                true
              );
              this.$set(
                this.classifyList[topArray[0]].children[topArray[1]].children[
                  topArray[2]
                ],
                "threechecked",
                true
              );
            }
            if (
              topArray[0] != undefined &&
              topArray[1] != undefined &&
              topArray[2] != undefined &&
              topArray[3] !== undefined
            ) {
              this.$set(this.classifyList[topArray[0]], "flag", true);
              this.$set(
                this.classifyList[topArray[0]].children[topArray[1]],
                "flag",
                true
              );
              this.$set(
                this.classifyList[topArray[0]].children[topArray[1]].children[
                  topArray[2]
                ],
                "flag",
                true
              );
              this.$set(
                this.classifyList[topArray[0]].children[topArray[1]].children[topArray[2]]
                  .children[topArray[3]],
                "fourchecked",
                true
              );
            }
          } else {
            // topArray没有值，代表是本页面进行操作，没有其他页面的值需要回显

            // 根据记录的左侧筛选树结构的分类id，利用递归方法查询classifyList，获取对应具体的分类数据项
            let pathArr = getPathByKey(
              this.titleIndex == 1
                ? this.$store.state.searchParam.subjectIds[0]
                : this.$store.state.searchParam.clcIds[0],
              this.classifyList
            );
            /**
             *  topArray1 重新构建记录点击的分类数据项
             *  每个分类数据项都有排序Order字段代表这个数据项在哪个位置
             *  是从1开始的，那么在数组中的下标也就是从order-1开始的
             *  将每个数据项的下标处理存储，在拿到新数据的时候，由于数据结构保持一致
             *  只是里面count字段的内容有所改变，所以可以根据下标去找到对应的数据项
             *  将其以及父节点进行样式修改高亮展开等操作
             * **/
            let topArray1 = [];
            pathArr.forEach(element => {
              let index = element.order - 1;
              topArray1.push(index);
            });
            /**
             * 当前分类树最多四层
             * 1.当只有一个数据的时候topArray1[0] != undefined，则是根节点全部
             *  - 设置根节点被选中的效果this.$set(this.classifyList[topArray1[0]], "onechecked", true);
             *
             * **/
            if (
              topArray1[0] != undefined &&
              topArray1[1] == undefined &&
              topArray1[2] == undefined &&
              topArray1[3] == undefined
            ) {
              this.$set(this.classifyList[topArray1[0]], "onechecked", true);
            } else if (
              /**
               * 当只有两个数据的时候
               * 根节点展开flag：true
               * 第二层数据被选中twochecked:checked
               * 判断旧数据oldValue中该项flag是否展开
               *
               * **/
              topArray1[0] != undefined &&
              topArray1[1] != undefined &&
              topArray1[2] == undefined &&
              topArray1[3] == undefined
            ) {
              if (this.classifyList[topArray1[0]].flag == undefined) {
                this.$set(this.classifyList[topArray1[0]], "flag", true);
              }

              this.$set(
                this.classifyList[topArray1[0]].children[topArray1[1]],
                "twochecked",
                true
              );
            } else if (
              topArray1[0] != undefined &&
              topArray1[1] != undefined &&
              topArray1[2] != undefined &&
              topArray1[3] == undefined
            ) {
              if (this.classifyList[topArray1[0]].flag == undefined) {
                this.$set(this.classifyList[topArray1[0]], "flag", true);
              }
              if (
                this.classifyList[topArray1[0]].children[topArray1[1]].flag == undefined
              ) {
                this.$set(
                  this.classifyList[topArray1[0]].children[topArray1[1]],
                  "flag",
                  true
                );
              }

              this.$set(
                this.classifyList[topArray1[0]].children[topArray1[1]].children[
                  topArray1[2]
                ],
                "threechecked",
                true
              );
            } else if (
              topArray1[0] != undefined &&
              topArray1[1] != undefined &&
              topArray1[2] != undefined &&
              topArray1[3] !== undefined
            ) {
              if (this.classifyList[topArray1[0]].flag == undefined) {
                this.$set(this.classifyList[topArray1[0]], "flag", true);
              }
              if (
                this.classifyList[topArray1[0]].children[topArray1[1]].flag == undefined
              ) {
                this.$set(
                  this.classifyList[topArray1[0]].children[topArray1[1]],
                  "flag",
                  true
                );
              }
              if (
                this.classifyList[topArray1[0]].children[topArray1[1]].children[
                  topArray1[2]
                ].flag == undefined
              ) {
                this.$set(
                  this.classifyList[topArray1[0]].children[topArray1[1]].children[
                    topArray1[2]
                  ],
                  "flag",
                  true
                );
              }

              this.$set(
                this.classifyList[topArray1[0]].children[topArray1[1]].children[
                  topArray1[2]
                ].children[topArray1[3]],
                "fourchecked",
                true
              );
            }
            this.updateExpandItem();
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      lineImg: require("@/assets/images/line13.png"),
      firstnochildImg: require("@/assets/images/line10.png"),
      nochildImg: require("@/assets/images/line11.png"),
      lastnochildImg: require("@/assets/images/line12.png"),
      firstAddImg: require("@/assets/images/line14.png"),
      firstJianImg: require("@/assets/images/line15.png"),
      addImg: require("@/assets/images/line04.png"),
      jianImg: require("@/assets/images/line16.png"),
      xiala: require("@/assets/images/icon-xiala.png"),
      shouqi: require("@/assets/images/icon-shouqi.png"),
      expandKeys: [-1],
      classifyList: [],
      commonData: [],
      leftObj: {},
      yearIndex: null,
      yearMore: false,
      titleIndex: 1,
      topArray: [],
      text: false,
    };
  },
  created() {},
  mounted() {
    // 与公共组件top 通信 获取点击事件  默认展开选中分类
    // this.$bus.$on("childByVal", (res) => {
    // 	console.log(this.classifyList);
    // 	this.topArray = res
    // 	console.log(this.topArray);
    // 	if(this.topArray[0]){
    // 		console.log(11111111);
    // 		console.log(this.classifyList[this.topArray[0]]);
    // 		this.$set(this.classifyList[this.topArray[0]],'flag',true)
    // 	}
    // })

    this.$bus.$on("close", data => {
      if (data == "clcIds" || data == "subjectIds") {
        this.clearStyle();
      } else {
        this.yearIndex = null;
      }
    });
  },
  methods: {
    // 修改展开数据
    updateExpandItem() {
      this.expandKeys.forEach(key => {
        let pathArr = getPathByKey(key, this.classifyList);
        let topArray1 = [];
        pathArr.forEach(element => {
          let index = element.order - 1;
          topArray1.push(index);
        });
        if (topArray1.length == 1) {
          this.$set(this.classifyList[topArray1[0]], "flag", true);
        } else if (topArray1.length == 2) {
          this.$set(this.classifyList[topArray1[0]].children[topArray1[1]], "flag", true);
        } else if (topArray1.length == 3) {
          this.$set(
            this.classifyList[topArray1[0]].children[topArray1[1]].children[topArray1[2]],
            "flag",
            true
          );
        }
      });
    },
    // computedItems(item) {
    //   if (item.addIndex == undefined) {
    //     item.addIndex = 1;
    //   }
    //   return item.aggItems.slice(0, item.addIndex * 5);
    // },
    handleChangeTitle(index) {
      this.clearStyle();
      if (this.titleIndex != index) {
        this.$store.dispatch("updateField", {
          key: "subjectIds",
          value: [],
        });
        this.$store.dispatch("updateField", {
          key: "clcIds",
          value: [],
        });

        this.$emit("leftsearch", "clear");
      }
      this.titleIndex = index;
      if (this.titleIndex == 1) {
        this.classifyList = this.leftObj["图书分类"];
      } else if (this.titleIndex == 2) {
        this.classifyList = this.leftObj["中图法分类"];
      }
    },
    // 处理判断展开的ID
    handleExpandKeys(id) {
      // 判断expandKeys中有无该节点
      let id_index = -1;
      for (let i = 0; i < this.expandKeys.length; i++) {
        if (this.expandKeys[i] == id) {
          id_index = i;
          break;
        }
      }
      if (id_index == -1) {
        this.expandKeys.push(id);
      } else {
        this.expandKeys.splice(id_index, 1);
      }
    },
    handleOneChild(index, id) {
      this.handleExpandKeys(id);
      if (this.classifyList[index].flag == undefined) {
        this.$set(this.classifyList[index], "flag", false);
      } else if (!this.classifyList[index].flag) {
        this.$set(this.classifyList[index], "flag", true);
      } else {
        this.$set(this.classifyList[index], "flag", false);
      }
      this.$forceUpdate();
    },
    // 三级全部清空选中样式
    clearStyle() {
      this.classifyList.forEach(element => {
        element.onechecked = false;
        if (element.children) {
          element.children.forEach(item => {
            item.twochecked = false;
            if (item.children) {
              item.children.forEach(menu => {
                menu.threechecked = false;
                if (menu.children) {
                  menu.children.forEach(four => {
                    four.fourchecked = false;
                  });
                }
              });
            }
          });
        }
      });
      // console.log(this.classifyList);
      this.$forceUpdate();
    },
    setLeftData(val, names) {
      let obj = {};
      if (this.titleIndex == 1) {
        obj.key = "subjectIds";
        obj.value = [val.id];
        this.$store.dispatch("updateField", obj);
        // this.$store.dispatch('setLeft',{'subjectIds':names})
      } else if (this.titleIndex == 2) {
        obj.key = "clcIds";
        obj.value = [val.id];
        this.$store.dispatch("updateField", obj);
        // this.$store.dispatch('setLeft',{'clcIds':names})
      }
    },
    handleOneChecked(index, id) {
      this.clearStyle();
      this.$set(this.classifyList[index], "onechecked", true);
      let names = this.classifyList[index].name;
      this.setLeftData(this.classifyList[index]);
      this.$emit(
        "leftsearch",
        this.titleIndex == 1 ? "subjectIds" : "clcIds",
        this.classifyList[index],
        names
      );
      this.handleOneChild(index, id);
    },
    handleTwoChecked(index, idx, id) {
      this.clearStyle();
      this.$set(this.classifyList[index].children[idx], "twochecked", true);
      let names = `${this.classifyList[index].name} > ${this.classifyList[index].children[idx].name}`;
      this.setLeftData(this.classifyList[index].children[idx]);
      this.$emit(
        "leftsearch",
        this.titleIndex == 1 ? "subjectIds" : "clcIds",
        this.classifyList[index].children[idx],
        names
      );
      this.handleTwoChild(index, idx, id);
    },
    handleTwoChild(index, idx, id) {
      this.handleExpandKeys(id);
      if (!this.classifyList[index].children[idx].flag) {
        this.$set(this.classifyList[index].children[idx], "flag", true);
      } else {
        this.$set(this.classifyList[index].children[idx], "flag", false);
      }
    },
    handleThreeChild(index, idx, listidx, id) {
      this.handleExpandKeys(id);
      if (!this.classifyList[index].children[idx].children[listidx].flag) {
        this.$set(this.classifyList[index].children[idx].children[listidx], "flag", true);
      } else {
        this.$set(
          this.classifyList[index].children[idx].children[listidx],
          "flag",
          false
        );
      }
    },
    handleThreeChecked(index, idx, threeidx, id) {
      this.clearStyle();
      this.$set(
        this.classifyList[index].children[idx].children[threeidx],
        "threechecked",
        true
      );
      let names = `${this.classifyList[index].name} > ${this.classifyList[index].children[idx].name} > ${this.classifyList[index].children[idx].children[threeidx].name}`;
      this.setLeftData(this.classifyList[index].children[idx].children[threeidx]);
      this.$emit(
        "leftsearch",
        this.titleIndex == 1 ? "subjectIds" : "clcIds",
        this.classifyList[index].children[idx].children[threeidx],
        names
      );
      this.handleThreeChild(index, idx, threeidx, id);
    },
    handleFourChecked(index, idx, threeidx, fouridx) {
      this.clearStyle();
      this.$set(
        this.classifyList[index].children[idx].children[threeidx].children[fouridx],
        "fourchecked",
        true
      );
      let names = `${this.classifyList[index].name} > ${this.classifyList[index].children[idx].name} > ${this.classifyList[index].children[idx].children[threeidx].name} > ${this.classifyList[index].children[idx].children[threeidx].children[fouridx].name}`;
      this.setLeftData(
        this.classifyList[index].children[idx].children[threeidx].children[fouridx]
      );
      this.$emit(
        "leftsearch",
        this.titleIndex == 1 ? "subjectIds" : "clcIds",
        this.classifyList[index].children[idx].children[threeidx].children[fouridx],
        names
      );
    },

    handleYear(index, item, item1) {
      this.yearIndex = index;
      this.$emit(
        "leftsearch",
        "publishYear",
        item,
        item.name,
        this.yearIndex,
        this.yearMore,
        item1.addIndex ? item1.addIndex : 1
      );
    },
    handleChangeFlag(item, type) {
      this.yearMore = type;
      if (this.yearMore) {
        //收起
        item.addIndex = 1;
      } else {
        item.addIndex++;
      }
      if (item.aggItems.length >= item.addIndex * 5) {
        this.yearMore = false;
      } else {
        this.yearMore = true;
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 1rem;
}
.verticalline {
  background-image: url(../assets/images/line13.png);
  background-position: 10px 0;
  background-repeat: repeat-y;
}
.line1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menu_main {
  background: #f7f7f7;
  color: #333;
  font-size: 16px;
  width: 270px;
  padding: 0 10px;
  padding-bottom: 10px;
  height: fit-content;
  .menu-title {
    padding: 20px 0 15px;
    font-size: 20px;
    .checked {
      color: #078bef;
    }
    span {
      flex: 1;
      text-align: center;
      position: relative;
      cursor: pointer;
      &:nth-of-type(1) {
        &::after {
          content: "";
          position: absolute;
          right: 0;
          width: 1px;
          height: 18px;
          background: #b3b3b3;
          top: 50%;
          margin-top: -8px;
          margin-right: 4px;
        }
      }
    }
  }

  .ztree {
    display: block;
    overflow: hidden;

    background: #fff;
  }
  .menu-item {
    padding: 10px;
    white-space: nowrap;
    outline: 0;

    li {
      white-space: nowrap;
      outline: 0;
    }
    .menu-one {
      margin-bottom: 10px;

      &:last-of-type {
        margin: 0;
      }
      img {
        // width: 15px;
        // height: 15px;
        // margin-right: 10px;
        cursor: pointer;
      }
      .menu-one-page {
        padding: 5px 10px;
        align-items: center;
        span {
          cursor: pointer;
        }
        .onechecked {
          color: #078bef;
        }
      }
      .menu-two {
        // padding-left: 25px;
        padding-left: 1rem;
        color: #666666;
        .menu-two-page {
          color: #666666;
          padding: 5px 10px;
          align-items: center;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
          }
          span {
            cursor: pointer;
            display: inline-block;
            max-width: 70%;
          }
          .twochecked {
            color: #078bef;
          }
        }
        .menu-three {
          // padding-left: 25px;
          padding-left: 1rem;
          color: #666666;
          .menu-three-page {
            color: #666666;
            padding: 5px 10px;
            align-items: center;
            > div {
              display: flex;
              align-items: center;
              width: 100%;
            }
            span {
              cursor: pointer;
              display: inline-block;
              max-width: 70%;
            }
          }
          .threechecked {
            color: #078bef;
          }
        }
        .menu-four {
          // padding-left: 25px;
          padding-left: 1.1rem;
          color: #666666;
          .menu-four-page {
            padding: 5px 10px;
            cursor: pointer;
            padding-left: 10px;
            .tree3 {
              display: flex;
              width: 100%;
              span {
                max-width: 70%;
              }
            }
          }
          .fourchecked {
            color: #078bef;
          }
        }
      }
    }
  }
  .title-year {
    position: relative;
    padding-left: 35px;
    &::after {
      content: "";
      position: absolute;
      left: 10px;
      top: 57%;
      height: 2px;
      width: 12px;
      background: #078bef;
    }
  }
  .year-item {
    background: #fff;
    padding: 10px;
    ul {
      li {
        padding: 5px 10px;
        cursor: pointer;
        // margin-bottom: 10px;
      }
      .checked {
        color: #078bef;
      }
    }
    p {
      color: #078bef;
      padding-left: 10px;
      margin-top: 10px;
      cursor: pointer;
      img {
        margin-left: 10px;
      }
    }
  }
}
</style>
